<template>
  <v-slide-item
    height="100%"
    class="d-inline-block single-item me-3"
    :class="{ desktop: !isMobile }"
  >
    <nuxt-link :to="link" :title="title">
      <v-card v-ripple height="100%" flat class="pa-3">
        <div :class="{ blure: item.placeholder }">
          <v-row no-gutters>
            <v-col cols="5">
              <AnswerCount :model="item" :linking="false" />
            </v-col>
            <v-col cols="7" class="ps-2 ps-lg-3">
              <div v-if="item.type === 'urgent' || item.type === 'secret'">
                <div v-if="item.type === 'urgent'" class="tag urgent warning">
                  <i class="icon-12 v-icon icon-flash-circle" />
                  <span
                    class="text-caption"
                  ><strong>{{ $t('consultation.urgent') }}</strong></span>
                </div>
                <div v-else class="tag grey darken-3">
                  <i class="white--text icon-12 v-icon icon-sunglasses" />
                  <span
                    class="white--text text-caption"
                  ><strong>{{ $t('consultation.private') }}</strong></span>
                </div>
              </div>
              <p
                v-else
                class="ma-0 text-caption grey--text text--darken-2 mb-10"
              />
              <p class="ma-0 text-caption grey--text text--darken-2 mt-3">
                {{ item.categoryName }}
              </p>
              <p
                v-if="!item.placeholder"
                class="ma-0 text-caption grey--text text--darken-2"
              >
                <i
                  class="grey--text text--darken-2 icon-12 v-icon icon-clock-outline"
                />
                {{ $t('consultation.asked') }} {{ item.localTime }}
              </p>
              <p class="ma-0 text-caption grey--text text--darken-2">
                <i
                  class="grey--text text--darken-2 icon-12 v-icon icon-eye-line"
                />
                <span
                  v-if="
                    currentLang !== 'ar' ||
                      (currentLang === 'ar' &&
                        item.total_visits !== 1 &&
                        item.total_visits !== 2)
                  "
                >{{ item.visits }}&nbsp;</span>{{ $t(getProperLabel(item.total_visits, 'Vists')) }}
              </p>
            </v-col>
          </v-row>
          <v-row no-gutters>
            <v-col cols="12">
              <h2 class="text-h6 mt-1 q-title">
                {{ title }}
              </h2>
              <p
                v-if="!item.audio_file_id"
                class="mb-0 text-body-2 grey--text text--darken-3"
                v-html="excerpt"
              />
              <p v-else class="mb-0 text-body-2 grey--text text--darken-3">
                <i
                  class="grey--text text--darken-3 icon-small v-icon icon-mic"
                />
                {{ $t('consultation.voice') }}
              </p>
              <LastAnswer
                v-if="item.lastAnswer"
                :linked="false"
                :model="item.lastAnswer"
                class="mt-2"
              />
            </v-col>
          </v-row>
        </div>
        <div v-if="item.isUpgradable" class="mt-2">
          <v-btn
            :to="
              localePath({
                name: 'pay',
                query: {
                  consultationid: item.id,
                  serviceid: item.upgradingServiceId,
                },
              })
            "
            small
            color="primary"
            nuxt
            class="no-hover"
          >
            <i class="me-2 mt-1 v-icon icon-small icon-flash-circle" />
            {{ $t('consultation.upgradeCta') }}
          </v-btn>
        </div>
        <div v-if="item.placeholder" class="expert-notice">
          <p>
            <strong>{{ $t('consultation.private') }}</strong><br><small>{{ $t('consultation.onlyExperts') }}</small>
          </p>
        </div>
      </v-card>
    </nuxt-link>
  </v-slide-item>
</template>

<script>
import { truncateText, getProperLabel } from '@/utils'

import AnswerCount from '@/components/ConsultationComponents/ConsultationBlockComponents/AnswersCount'
import LastAnswer from '@/components/ConsultationComponents/ConsultationBlockComponents/LastAnswer'

export default {
  name: 'Item',

  components: {
    AnswerCount,
    LastAnswer
  },

  props: {
    item: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    itemsLength: {
      type: Number,
      required: true
    }
  },

  data () {
    return {
      getProperLabel,
      currentLang:
        this.$store.state.currentLang || this.$i18n.locale.substring(0, 2),
      title: truncateText(this.item.title, 40),
      excerpt: truncateText(this.item.body, 80),
      link:
        this.item.type !== 'secret' ||
        (this.item.type === 'secret' &&
          this.$auth.user &&
          ['expert', 'admin'].includes(this.$auth.user.role))
          ? this.localePath({
            name: 'consultations-id-slug',
            params: { id: this.item.id, slug: this.item.slug }
          })
          : this.localePath({ name: 'consultation' })
    }
  },

  computed: {
    isMobile () {
      return this.$store.state.isMobile
    }
  }
}
</script>

<style lang="scss" scoped>
.single-item {
  width: 270px;
  max-width: 270px;
  .q-title {
    font-weight: bold;
  }
  &:hover {
    text-decoration: none;
    opacity: 0.8;
    .q-title {
      color: $mahkamaty-blue-color-alt !important;
    }
  }
  &.desktop {
    width: 370px !important;
    max-width: unset;
    overflow: hidden;
  }
}
.v-card {
  border-radius: $standard-radius;
}
.text-caption {
  line-height: 0.95rem !important;
}
.tag {
  display: inline;
  padding: 0 10px;
  border-radius: $small-radius;
  &.urgent {
    background: $default-accent-color;
    color: #502121 !important;
  }
}
.blure {
  filter: blur(6px);
  opacity: 0.5;
  .tag {
    opacity: 0.5;
  }
}
.expert-notice {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
</style>
